<template>
  <div>
    <v-row class="mt-12">
      <v-col>
        <span class="product__related__header">สินค้าที่เกี่ยวข้อง</span>
      </v-col>
    </v-row>
    <v-row class="mb-12">
      <v-col
        v-for="(product, n) in productRelated"
        :key="`productRelated-${n}`"
        cols="12"
        sm="6"
        md="3"
      >
        <ProductCard 
          :product="product" 
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';

export default {
  name: 'ProductRelated',
  components: {
    ProductCard,
  },
  props: {
    productRelated: {
      type: Array,
      default: () => {},
    },
  },
};
</script>
