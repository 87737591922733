<template>
  <v-container :class="productRelated.length === 0 ? 'mb-16' : ''">
    <ProductDetail
      :product="product"
    />
    <ProductRelated
      v-if="productRelated.length > 0"
      :product-related="productRelated"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_PRODUCT, GET_PRODUCT_RELATED } from '@/store/_actionTypes';

import ProductRelated from './components/ProductRelated.vue';
import ProductDetail from './components/ProductDetail.vue';

export default {
  name: 'Product',
  components: {
    ProductRelated,
    ProductDetail,
  },
  created() {
    this[GET_PRODUCT]({ productId: this.$route.params.productId, accessToken: this.accessToken });
  },
  computed: {
    ...mapState('user', [ 'accessToken' ]),
    ...mapState('product', [ 'product', 'productRelated' ]),
  },
  methods: {
    ...mapActions('product', [ GET_PRODUCT, GET_PRODUCT_RELATED ]),
  },
  watch: {
    product() {
      this[GET_PRODUCT_RELATED]({
        productId: this.$route.params.productId,
        categoryId: this.product.categoryId,
        accessToken: this.accessToken,
      });

      document.title = `Narinonlin | นรินทร์เภสัช - ${this.product.name}`;
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/product/product.scss';
</style>
