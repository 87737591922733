<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-col class="d-flex justify-center align-center product__image__container pa-16">
        <div
          v-if="isPromotionValid()"
          class="product__tag__discount d-flex justify-center align-center"
        >
          <span>{{ getPromotionBadge() }}</span>
        </div>
        <img
          height="400"
          :src="getProductImage()"
        />
      </v-col>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <div class="product__detail__container d-flex flex-column">
        <span class="product__detail__header">{{ product.name }}</span>
        <span class="product__detail__sub__header">{{ product.genericName }}</span>
        <div class="product__detail__detail">
          <span>รหัสสินค้า: {{ product.productCode }}</span>
          <span class="ml-4 ml-md-16">Barcode: {{ product.barcode }}</span>
        </div>
        <span class="product__detail__detail">บริษัท: {{ product.manufacturer || '-'}}</span>
        <div>
          <span class="product__detail__detail">รายละเอียดและข้อระวังในการใช้ยา</span>
          <v-tooltip
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="secondary"
                dark
                v-bind="attrs"
                dense
                class="ml-1"
                v-on="on"
              >
                mdi-help-circle
              </v-icon>
            </template>
            <div class="tooltips__container d-flex flex-column">
              <span class="tooltips__header">รายละเอียดและข้อระวังในการใช้ยา</span>
              <span class="tooltips__detail">{{ product.description }}</span>
            </div>
          </v-tooltip>
        </div>

        <div class="product__detail__table__container">
          <table class="product__detail__table">
            <tr
              v-for="(priceRate, n) in getPriceRates()"
              :key="`priceRate-${n}`"
            >
              <td>จำนวน {{ priceRate.minQty }} {{ product.unit }} ขึ้นไป</td>
              <td>ราคา ฿{{ formatNumber(priceRate.priceUnit) }}/{{ product.unit }}</td>
            </tr>
          </table>
        </div>
        <span class="product__detail__detail__2">ขนาดบรรจุ: {{ product.qtyPerUnit }}</span>
        <div class="product__detail__detail__2 d-flex align-center mt-3">
          <span class="mr-4">จำนวน</span>
          <div class="product__detail__input__number">
            <vue-number-input
              v-model="qty"
              controls
              :min="1"
              :max="product.stock ? product.stock: 999999999"
              center
              @input="onKeypress"
            />
          </div>
          <span class="ml-4">{{ product.unit }} {{ product.stock ? `(สูงสุด : ${product.stock})` : "" }}</span>
        </div>
        <div class="mt-3">
          <img :src="require('@/assets/images/medicine/nrn-drugs-capsules.png')">
          <span class="product__detail__capsule__text ml-2">ได้รับ</span>
          <span class="product__detail__capsule"> {{ getTotalCapsule() }} Capsule Points</span>
        </div>
        <div class="product__detail__price__container d-flex justify-space-between align-center mt-3 px-3 py-3">
          <div>
            <span
              v-if="isDiscountPromotionValid(product)"
              class="product__detail__price__discount mr-2"
            >
              {{ getTotalPriceWithoutDiscount() }}
            </span>
            <span class="product__detail__price">{{ getTotalPriceText() }}</span>
          </div>
          <div
            v-if="isDiscountPromotionValid(product)"
            class="product__detail__discount__container"
          >
            <span>{{ getDiscountSummary() }}</span>
          </div>
        </div>
        <div
          v-if="product.deliveryFee && product.deliveryFee != 0"
          class="delivery-fee-text mt-3"
        >
          <span>{{ getDeliveryFee() }}</span>
          <br>
          <span>( คิดค่าจัดส่งสำหรับกรณีจัดส่งผ่านขนส่งเอกชนเท่านั้น )</span>
        </div>
        <div class="d-flex mt-3">
          <v-btn
            :class="product.visibility == 0 ? 'product__card__basket__btn mt-2 mr-6 py-6 disabled' : 'product__card__basket__btn mt-2 mr-6 py-6'"
            color="secondary"
            depressed
            @click="addToCart"
          >
            <v-icon class="mr-2">
              mdi-basket
            </v-icon>
            เพิ่มลงในตะกร้าสินค้า
          </v-btn>
          <v-btn
            class="product__card__heart__btn mt-2 py-6"
            :dark="isFavorites(product.id) ? true : false"
            :outlined="isFavorites(product.id) ? false : true"
            depressed
            :color="isFavorites(product.id) ? 'pink' : 'secondary'"
            @click="addToWishList(isFavorites(product.id))"
          >
            <v-icon>{{ isFavorites(product.id) ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { ADD_ITEM, CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR, ADD_WISHLIST_PRODUCT, REMOVE_WISHLIST_PRODUCT, UPDATE_FAVORITES } from '@/store/_actionTypes';
import { formatNumber } from "@/helpers/number";
import {
  isDiscountAmountPromotionValid,
  isDiscountPercentagePromotionValid,
  isDiscountPromotionValid,
  applyCapsulePromotion,
  isFreeGiftPromotionValid,
  isCapsuleAmountPromotionValid,
  isCapsuleMultiplyPromotionValid,
} from "@/helpers/promotion";
import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';

export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    qty: 1,
  }),
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapState('user', [ 'accessToken', 'favorites' ]),
    ...mapState('cart', [ 'capsuleConfig' ]),
  },
  methods: {
    ...mapActions('cart', [ ADD_ITEM ]),
    ...mapActions('user', [ UPDATE_FAVORITES ]),
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('wishlist', [ ADD_WISHLIST_PRODUCT, REMOVE_WISHLIST_PRODUCT ]),
    formatNumber,
    isDiscountPromotionValid,
    isPromotionValid() {
      return isDiscountAmountPromotionValid(this.product)
        || isDiscountPercentagePromotionValid(this.product)
        || isFreeGiftPromotionValid(this.product)
        || isCapsuleAmountPromotionValid(this.product)
        || isCapsuleMultiplyPromotionValid(this.product);
    },
    isFavorites(productId) {
      return this.favorites.includes(productId);
    },
    addToWishList(isWishlist) {
      if (!this.isLoggedIn) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณาเข้าสู่ระบบก่อนใช้งาน');
        this[CHANGE_SNACKBAR_COLOR]('red');
      } else {
        if (isWishlist) {
          this[REMOVE_WISHLIST_PRODUCT]({ product: this.product, accessToken: this.accessToken });
          this[UPDATE_FAVORITES](this.favorites.filter(item => item !== this.product.id));
        } else {
          let favorites = [...this.favorites, this.product.id];
          this[ADD_WISHLIST_PRODUCT]({ product: this.product, accessToken: this.accessToken });
          this[UPDATE_FAVORITES](favorites);
        }
      }
    },
    addToCart() {
      if (this.product.visibility == 0) return;

      const product = {
        id: this.product.id,
        barcode: this.product.barcode,
        name: this.product.name,
        genericName: this.product.genericName,
        qty: this.qty,
        priceRates: this.product.priceRates,
        promotionType: this.product.promotionType,
        promotionFreeBuy: this.product.promotionFreeBuy,
        promotionFreeGift: this.product.promotionFreeGift,
        deliveryFee: this.product.deliveryFee,
        discount: this.product.promotionDiscountAmount,
        discountPercentage: this.product.promotionDiscountPercentage,
        promotionCapsuleAmount: this.product.promotionCapsuleAmount,
        promotionCapsuleMultiply: this.product.promotionCapsuleMultiply,
        promotionStartdate: this.product.promotionStartdate,
        promotionEnddate: this.product.promotionEnddate,
        capsule: this.product.capsule,
        unit: this.product.unit,
        image: this.getProductImage(),
        isCapsule: false,
        selected: true,
        stock: this.product.stock
      }
      this[ADD_ITEM]({ qty: this.qty, product });
    },
    getPromotionBadge() {
      if (isDiscountAmountPromotionValid(this.product))
        return `-฿${this.product.promotionDiscountAmount}`;
      if (isDiscountPercentagePromotionValid(this.product))
        return `-${this.product.promotionDiscountPercentage}%`;
      if (isFreeGiftPromotionValid(this.product))
        return `${this.product.promotionFreeBuy} แถม ${this.product.promotionFreeGift}`;
      if (isCapsuleAmountPromotionValid(this.product))
        return `+${this.product.promotionCapsuleAmount} capsules`;
      if (isCapsuleMultiplyPromotionValid(this.product))
        return `x${this.product.promotionCapsuleMultiply} capsules`;
    },
    getDiscountSummary() {
      if (isDiscountAmountPromotionValid(this.product))
        return `รวมส่วนลด ${this.product.promotionDiscountAmount}฿`;
      if (isDiscountPercentagePromotionValid(this.product))
        return `รวมส่วนลด ${this.product.promotionDiscountPercentage}%`;
    },
    getTotalPriceWithoutDiscount() {
      return `฿${formatNumber(this.qty * this.getPrice())}`;
    },
    getTotalPriceText() {
      let price = this.getPrice();
      let discount = 0;

      if (isDiscountAmountPromotionValid(this.product)) {
        discount = this.product.promotionDiscountAmount;
        price = parseInt(price - discount);
      } else if (isDiscountPercentagePromotionValid(this.product)) {
        discount = this.product.promotionDiscountPercentage;
        price = parseInt(price - (price * discount / 100));
      }

      if (price < 0) price = 0;

      return `฿${formatNumber(this.qty * price)}`;
    },
    getTotalPriceNumber() {
      let price = this.getPrice();
      let discount = 0;

      if (isDiscountAmountPromotionValid(this.product)) {
        discount = this.product.promotionDiscountAmount;
        price = parseInt(price - discount);
      } else if (isDiscountPercentagePromotionValid(this.product)) {
        discount = this.product.promotionDiscountPercentage;
        price = parseInt(price - (price * discount / 100));
      }

      if (price < 0) price = 0;

      return this.qty * price;
    },
    getPrice() {
      if (this.product.priceRates === undefined || this.product.priceRates.length === 0) return 0;
      let priceRange = [...this.product.priceRates];
      const price = priceRange
        .filter(priceRate => priceRate.minQty <= this.qty)
        .sort((a, b) => b.minQty - a.minQty)[0];
      return price ? price.priceUnit : 1;
    },
    getPriceRates() {
      if (this.product.priceRates === undefined || this.product.priceRates.length === 0) return [];
      let priceRange = [...this.product.priceRates];
      return priceRange.sort((a, b) => a.minQty - b.minQty);
    },
    getTotalCapsule() {
      if (!this.product.capsule)
        return Math.floor(this.getTotalPriceNumber() / this.capsuleConfig);

      return applyCapsulePromotion(this.product, this.product.capsule, this.qty);
    },
    onKeypress(e) {
      this.qty = e.target.value ? parseInt(e.target.value) : 1;
    },
    getProductImage() {
      if (!this.product.images || this.product.images.length === 0)
        return NotFoundProductImage;

      return this.product.images[0].url;
    },
    getDeliveryFee() {
      return `* มีค่าจัดส่งพิเศษ ${this.product.deliveryFee} บาท ต่อ หน่วย`
    }
  }
};
</script>
